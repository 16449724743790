import ShadedDiv from './ShadedDiv'
import House from './House'
import './Ground.css'

export default function Ground({time}) {
  return <div className="ground">
    <House time={time}/>
    <ShadedDiv
      className="ground-grass"
      dayColor={{r: 12, g: 125, b: 2}}
      nightColor={{r: 0, g: 20, b: 0}}
      time={time}
    />
  </div>
}
