import {
  SUNSET,
  NIGHT_START,
  NIGHT_DURATION,
  SUNRISE,
  DAY_START,
  LIGHTS_ON_DUSK
} from './constants'
import {
  getProgress,
  interpolateColors,
} from './utils'
import ShadedDiv from './ShadedDiv'
import './Window.css'

const FRAME_DAY = {r: 95, g: 53, b: 34}
const FRAME_NIGHT = {r: 12, g: 7, b: 4}
const PANE_DAYTIME = {r: 230, g: 230, b: 255}
const PANE_LIGHTS_ON = {r: 255, g: 255, b: 0}
const PANE_LIGHTS_OUT = {r: 0, g: 0, b: 0}

export default function Window({time}) {
  let paneColor, progress
  if (time < SUNSET) {
    paneColor = PANE_DAYTIME
  } else if (time < LIGHTS_ON_DUSK) {
    progress = getProgress(SUNSET, NIGHT_START, time)
    paneColor = interpolateColors(PANE_DAYTIME, PANE_LIGHTS_OUT, progress)
  } else if (time < NIGHT_START + NIGHT_DURATION / 2) {
    paneColor = PANE_LIGHTS_ON
  } else if (time < SUNRISE) {
    paneColor = PANE_LIGHTS_OUT
  } else if (time < DAY_START) {
    progress = getProgress(SUNRISE, DAY_START, time)
    paneColor = interpolateColors(PANE_LIGHTS_OUT, PANE_DAYTIME, progress)
  } else {
    paneColor = PANE_DAYTIME
  }
  let paneColorCss = `rgb(${paneColor.r}, ${paneColor.g}, ${paneColor.b})`
  
  return <ShadedDiv
    className="window"
    dayColor={FRAME_DAY}
    nightColor={FRAME_NIGHT}
    time={time}
  >
    <div className="window-pane" style={{backgroundColor: paneColorCss}}/>
    <div className="window-pane" style={{backgroundColor: paneColorCss}}/>
    <div className="window-pane" style={{backgroundColor: paneColorCss}}/>
    <div className="window-pane" style={{backgroundColor: paneColorCss}}/>
  </ShadedDiv>
}