import {useEffect, useState} from 'react'
import {SUNSET, SUNRISE} from './constants'
import Ground from './Ground'
import Overlay from './Overlay'
import Sky from './Sky'
import './App.css';

const CYCLE_LENGTH = 10000

let faviconLink = document.querySelector("link[rel~='icon']");
if (!faviconLink) {
  faviconLink = document.createElement('link');
  faviconLink.rel = 'icon';
  document.getElementsByTagName('head')[0].appendChild(faviconLink);
}

function setFavicon(time) {
  if (time < SUNSET) {
    faviconLink.href = "./sun.favicon.ico"
  } else if (time < SUNRISE) {
    faviconLink.href = "./moon.favicon.ico"
  } else {
    faviconLink.href = "./sun.favicon.ico"
  }
}

export default function App() {
  const [scrollPosition, setScrollPosition] = useState(0)
  const [time, setTime] = useState(0)

  useEffect(() => {
    const cycle = Math.floor(scrollPosition / CYCLE_LENGTH)
    const cycleStart = cycle * CYCLE_LENGTH
    const currentTime = (scrollPosition - cycleStart) / CYCLE_LENGTH
    
    setTime(currentTime)
    setFavicon(currentTime)
  }, [scrollPosition])

  
  function handleScroll() {
    setScrollPosition(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  return (
    <div className="app">
      <div className="app-content"/>
      <Overlay time={time}/>
      <Sky time={time}/>
      <Ground time={time}/>
    </div>
  );
}
