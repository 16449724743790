import {
  SUNSET,
  LIGHTS_ON_DUSK,
  NIGHT_START,
  SUNRISE,
  LIGHTS_OFF_DAWN,
  DAY_START,
} from './constants'
import {getProgress, interpolateColors} from './utils'
import './Overlay.css'

const DAY_FONT_COLOR = {r: 0, g: 0, b: 0}
const DUSK_FONT_COLOR = {r: 255, g: 200, b: 0}
const NIGHT_FONT_COLOR = {r:234, g: 250, b: 255}

export default function Overlay({time}) {
  let fontColor, progress
  if (time < SUNSET) {
    fontColor = DAY_FONT_COLOR
  } else if (time < LIGHTS_ON_DUSK) {
    progress = getProgress(SUNSET, LIGHTS_ON_DUSK, time)
    fontColor = interpolateColors(DAY_FONT_COLOR, DUSK_FONT_COLOR, progress)
  } else if (time < NIGHT_START) {
    progress = getProgress(LIGHTS_ON_DUSK, NIGHT_START, time)
    fontColor = interpolateColors(DUSK_FONT_COLOR, NIGHT_FONT_COLOR, progress)
  } else if (time < SUNRISE) {
    fontColor = NIGHT_FONT_COLOR
  } else if (time < LIGHTS_OFF_DAWN) {
    progress = getProgress(SUNRISE, LIGHTS_OFF_DAWN, time)
    fontColor = interpolateColors(NIGHT_FONT_COLOR, DUSK_FONT_COLOR, progress)
  } else if (time < DAY_START) {
    progress = getProgress(LIGHTS_OFF_DAWN, DAY_START, time)
    fontColor = interpolateColors(DUSK_FONT_COLOR, DAY_FONT_COLOR, progress)
  } else {
    fontColor = DAY_FONT_COLOR
  }
  const fontColorCss = `rgb(${fontColor.r}, ${fontColor.g}, ${fontColor.b})`
  
  return <div className="overlay" style={{color: fontColorCss}}>
    <h1 className="overlay-headline">Berner Web Creations</h1>
  </div>
}