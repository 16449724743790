import {getColorFromCycle} from './utils'

export default function ShadedDiv({
  children,
  className,
  dayColor,
  nightColor,
  style,
  time,
}) {
  const color = getColorFromCycle(
    dayColor,
    nightColor,
    time,
  )
  
  return <div
    className={className}
    style={Object.assign(
      {},
      {backgroundColor: `rgb(${color.r}, ${color.g}, ${color.b})`},
      style,
    )}
  >
    {children}
  </div>
}
