import {
  SUNSET,
  NIGHT_START,
  SUNRISE,
  DAY_START,
} from './constants'

export function interpolate (from, to, progress) {
  return from + (to - from) * progress
}

export function interpolateColors(fromColor, toColor, progress) {
  return {
    r: interpolate(fromColor.r, toColor.r, progress),
    g: interpolate(fromColor.g, toColor.g, progress),
    b: interpolate(fromColor.b, toColor.b, progress),
  }
}

export function getProgress(from, to, current) {
  return (current - from) / (to - from)
}

export function getColorFromSeries(series, progress) {
  if (series.length === 0)
    return

  let previousColor = series[0]

  if (series.length === 1 || progress < series[0].stop){
    return series[0].color
  } else {
    for (let i = 1; i < series.length; i++) {
      if (previousColor.stop < progress && progress <= series[i].stop) {
        const fromColor = previousColor.color
        const toColor = series[i].color
        const stepProgress = getProgress(previousColor.stop, series[i].stop, progress)

        return interpolateColors(fromColor, toColor, stepProgress)
      }
      previousColor = series[i]
    }
    return series[series.length - 1].color
  }
}

export function getColorFromCycle(dayColor, nightColor, time) {
  let progress
  
  if (time < SUNSET) {
    return dayColor
  } else if (time < NIGHT_START) {
    progress = getProgress(SUNSET, NIGHT_START, time)
    return interpolateColors(dayColor, nightColor, progress)
  } else if (time < SUNRISE) {
    return nightColor
  } else if (time < DAY_START) {
    progress = getProgress(SUNRISE, DAY_START, time)
    return interpolateColors(nightColor, dayColor, progress)
  }
  return dayColor
}
