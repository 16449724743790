import {
  SUNSET,
  NIGHT_START,
  SUNRISE,
  DAY_START,
} from './constants'
import {
  getColorFromSeries,
  getProgress,
} from './utils'
import Sun from './Sun'
import './Sky.css'

const DAYLIGHT = [
  {color: 'rgb(175, 209, 249)', stop: '55%'},
  {color: 'rgb(255, 255, 255)'},
]
const DUSK_BOTTOM_SERIES = [
  {color: {r: 255, g: 255, b: 255}, stop: 0.05},
  {color: {r: 250, g: 50, b: 50}, stop: 0.1},
  {color: {r: 250, g: 150, b: 0}, stop: 0.7},
  {color: {r: 20, g: 20, b: 70}, stop: 1},
]
const DUSK_TOP_SERIES = [
  {color: {r: 175, g: 209, b: 249}, stop: 0},
  {color: {r: 0, g: 0, b: 30}, stop: 1},
]
const DAWN_BOTTOM_SERIES = [
  {color: {r: 20, g: 20, b: 70}, stop: 0},
  {color: {r: 250, g: 150, b: 0}, stop: 0.3},
  {color: {r: 250, g: 50, b: 50}, stop: 0.9},
  {color: {r: 255, g: 255, b: 255}, stop: 0.95},
]
const DAWN_TOP_SERIES = [
  {color: {r: 0, g: 0, b: 40}, stop: 0},
  {color: {r: 175, g: 209, b: 249}, stop: 1},
]
const NIGHT = [
  {color: 'rgb(0, 0, 40)', stop: '55%'},
  {color: 'rgb(20, 20, 70)'},
]

function getBackground(startTime, endTime, currentTime, topSeries, bottomSeries) {
  const progress = getProgress(startTime, endTime, currentTime)
  const topColor = getColorFromSeries(topSeries, progress)
  const bottomColor = getColorFromSeries(bottomSeries, progress)
  
  return [
    {color: `rgb(${topColor.r}, ${topColor.g}, ${topColor.b})`, stop: '45%'},
    {color: `rgb(${bottomColor.r}, ${bottomColor.g}, ${bottomColor.b})`},
  ]
}

export default function Sky({time}) {
  let backgroundParts

  if (time < SUNSET) {
    backgroundParts = DAYLIGHT
  } else if (time < NIGHT_START) {
    backgroundParts = getBackground(
      SUNSET,
      NIGHT_START,
      time,
      DUSK_TOP_SERIES,
      DUSK_BOTTOM_SERIES,
    )
  } else if (time < SUNRISE) {
    backgroundParts = NIGHT
  } else if (time < DAY_START) {
    backgroundParts = getBackground(
      SUNRISE,
      DAY_START,
      time,
      DAWN_TOP_SERIES,
      DAWN_BOTTOM_SERIES,
    )
  } else {
    backgroundParts = DAYLIGHT
  }

  return (
    <div
      className="sky"
      style={{background: `linear-gradient(${backgroundParts.map(part => `${part.color} ${part.stop || ''}`).join(',')})`}}
    >
      <Sun time={time}/>
    </div>
  );
}