import Window from './Window'
import {getColorFromCycle} from './utils'
import './House.css'

const DAY_BASE_COLOR = {r: 234, g: 207, b: 155}
const NIGHT_BASE_COLOR = {r: 18, g: 16, b: 12}
const DAY_ACCENT_COLOR = {r: 124, g: 30, b: 30}
const NIGHT_ACCENT_COLOR = {r: 10, g: 4, b: 4}
const DAY_BORDER_COLOR = {r: 128, g: 128, b: 128}
const NIGHT_BORDER_COLOR = {r: 0, g: 0, b: 0}

export default function House({time}) {
  const baseColor = getColorFromCycle(
    DAY_BASE_COLOR,
    NIGHT_BASE_COLOR,
    time
  )
  const baseColorCss = `rgb(${baseColor.r}, ${baseColor.g}, ${baseColor.b})`
  const borderColor = getColorFromCycle(
    DAY_BORDER_COLOR,
    NIGHT_BORDER_COLOR,
    time
  )
  const borderColorCss = `rgb(${borderColor.r}, ${borderColor.g}, ${borderColor.b})`
  const accentColor = getColorFromCycle(
    DAY_ACCENT_COLOR,
    NIGHT_ACCENT_COLOR,
    time
  )
  const accentColorCss = `rgb(${accentColor.r}, ${accentColor.g}, ${accentColor.b})`
  
  return <div className="house">
    <div className="house-lot">
      <div
        className="house-roof"
        style={{borderBottomColor: accentColorCss}}
      />
      <div
        className="house-secondFloor"
        style={{
          backgroundColor: baseColorCss,
          borderRightColor: borderColorCss,
          borderLeftColor: borderColorCss,
        }}
      >
        <Window time={time}/>
        <Window time={time}/>
        <Window time={time}/>
      </div>
      <div
        className="house-firstFloor"
        style={{
          backgroundColor: baseColorCss,
          borderRightColor: borderColorCss,
          borderLeftColor: borderColorCss,
        }}
      >
        <Window time={time}/>
        <div
          className="house-door"
          style={{backgroundColor: accentColorCss}}
        />
        <Window time={time}/>
      </div>
    </div>
  </div>
}