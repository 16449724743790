import './Sun.css'

const MAX_Y_EXTENSION = -60
const MAX_X_EXTENSION = 20

export default function Sun({time}) {
  const multiplierY = Math.cos(time * Math.PI * 2)
  const translationY = multiplierY * MAX_Y_EXTENSION
  const multiplierX = Math.sin(time * Math.PI * 2)
  const translationX = multiplierX * MAX_X_EXTENSION

  return <div className="sun-container">
    <div className="sun-pivotPoint">
      <div className="sun-sun" style={{
        transform: `translate(${translationX}vw, ${translationY}vh)`,
      }}/>
      <div className="sun-moon" style={{
        transform: `translate(${-translationX}vw, ${-translationY}vh)`,
      }}/>
    </div>
  </div>
}